<style scoped>
  .block {
    position: relative;
    min-width: 200px;
    min-height: 200px;
  }
  .status {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .2);
    backdrop-filter: blur(1px);
  }
</style>

<template>
  <Poptip trigger="click" title="请使用一远APP扫码登录" placement="right">
    <slot />
    <div slot="content" v-loadingx="loading" @click="getUuid" class="block">
      <img :src="qrSrc" v-if="qrSrc" style="width: 200px;" />
      <div class="status" v-if="status">{{status}}</div>
    </div>
  </Poptip>
</template>

<script>
import { request } from '@/api'
let QRCode = require('qrcode')
import { localstorageHelper } from '@/fmlib/index'

export default {
  data () {
    return {
      uuid: null,
      qrSrc: null,
      loading: false,
      status: null,
      timer: null
    }
  },
  methods: {
    async loadImg () {
      if (this.uuid) {
        this.qrSrc = await QRCode.toDataURL(JSON.stringify({
          type: 'app-login',
          uuid: this.uuid
        }))
      } else {
        this.qrSrc = null
      }
    },
    async query () {
      clearTimeout(this.timer)
      if (!this.uuid) {
        return
      }
      try {
        const res = await request('/ucenter/oauth/token', 'POST', {
          type:'qrcode', uuid: this.uuid, clientType: this.$store.getters.clientType
        })
        if (res.code === 1) {
          this.getUuid()
        } else if (res.code === 2) {
          this.timer = setTimeout(this.query, 1000)
        } else if (res.token) {
          this.$Message.success({ background: true, content: '登录成功' })
          localstorageHelper.updateData('token', res.token)
          this.$store.dispatch('authInit')
        }
      } catch (e) {
        this.timer = setTimeout(this.query, 1000)
      }
    },
    async getUuid () {
      if (this.loading) {
        return
      }

      clearTimeout(this.timer)
      this.uuid = null
      this.qrSrc = null
      this.status = '加载中...'
      this.loading = true

      try {
        const res = await request('/ucenter/oauth/uuid', 'GET')
        this.uuid = res.uuid
        this.loadImg()
        this.status = null
        this.timer = setTimeout(this.query, 1000)
      } catch (error) {
        this.uuid = null
        this.status = '加载失败，点击重试'
      }
      this.loading = false
    }
  },
  destroyed () {
    clearTimeout(this.timer)
    this.uuid = null
  },
  mounted () {
    this.getUuid()
  },
}
</script>
